import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';
import { Loader } from "@googlemaps/js-api-loader";
import { Autocomplete } from '@react-google-maps/api';
import { motion } from 'framer-motion';
import './NuevoProyecto.css';

const libraries = ['places'];

const NuevoProyecto = () => {
  const [formData, setFormData] = useState({
    proyecto: '',
    descripcion: '',
    fecha_inicio: '',
    fecha_termino: '',
    ubicacion: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user, usuario } = useUsuario();
  const [isUpdating, setIsUpdating] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [selectedValue, setSelectedValue] = useState(''); // Estado para el valor seleccionado


  
  // Cargar la API de Google Maps usando el Loader de @googlemaps/js-api-loader
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: libraries,
    });

    loader.load().then(() => {
      setIsApiLoaded(true); // La API se ha cargado correctamente
    }).catch((error) => {
      
      setError("Error al cargar la API de Google Maps");
    });
  }, []);

  useEffect(() => {
    const checkProjectLimit = async () => {
      const { data: proyectos, error } = await supabase
        .from('proyectos')
        .select('*')
        .eq('creador', user.id); // Filtrar proyectos por el ID del usuario

      if (error) {
        setError('Error al obtener los proyectos');
        return;
      }

      if (proyectos.length >= 20) {
        navigate('/precios'); // Redirigir a la página de precios si el usuario tiene 6 o más proyectos
      }
    };

    checkProjectLimit();
  }, [user.id, navigate]); // Dependencias para el useEffect

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
    
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const formattedAddress = place.formatted_address;
      

      setFormData((prevData) => {
        
        return {
          ...prevData,
          ubicacion: formattedAddress // Actualiza formData con la dirección formateada
        };
      });

      setSelectedValue(formattedAddress); // Actualiza el selectedValue con el valor seleccionado
      
    } else {
      
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setFormData((prevData) => {
      
      return {
        ...prevData,
        [name]: value
      };
    });

    // Si el campo que se está cambiando es 'ubicacion', no actualices selectedValue aquí
    if (name === 'ubicacion') {
      
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    // Validar que la fecha de término, si se ingresa, sea mayor o igual a la fecha de inicio
    if (formData.fecha_termino && new Date(formData.fecha_termino) < new Date(formData.fecha_inicio)) {
        setError('La fecha de término debe ser mayor o igual a la fecha de inicio');
        setIsUpdating(false);
        return;
    }

    // Validar que si se ingresa el correo, no esté vacío
    if (formData.correo && formData.correo.trim() === '') {
        setError('El correo no puede estar vacío si se ingresa');
        setIsUpdating(false);
        return;
    }

    // Validar que si se ingresa el encargado, no esté vacío
    if (formData.encargado && formData.encargado.trim() === '') {
        setError('El nombre del encargado no puede estar vacío si se ingresa');
        setIsUpdating(false);
        return;
    }
    console.log('Datos del formulario:', formData);
    const proyectoData = {
        proyecto: formData.proyecto,
        descripcion: formData.descripcion,
        ubicacion: formData.ubicacion,
        key: Date.now(),
        creador: user.id,
        url_imagen: imageUrl || null,
        url_carpeta: formData.url_carpeta,
        encargado: formData.encargado,
        correo: formData.correo,
        // No incluir fechas si no tienen valor
        ...(formData.fecha_inicio && { fecha_inicio: formData.fecha_inicio }),
        ...(formData.fecha_termino && { fecha_termino: formData.fecha_termino })
    };

    // Lógica para subir la imagen solo si se seleccionó
    if (image) {
      console.log('Subiendo imagen');
        try {
            const fileExt = image.name.split('.').pop();
            const fileName = `${proyectoData.key}/${Date.now()}.${fileExt}`;
            const filePath = `${fileName}`;

            const { error: uploadError } = await supabase
                .storage
                .from('proyectos')
                .upload(filePath, image);

            if (uploadError) {
                console.log('Error al subir la imagen');
                throw uploadError;
            }
            const imageUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/proyectos/${filePath}`;
            setImageUrl(imageUrlToUpdate);
            proyectoData.url_imagen = imageUrlToUpdate;

        } catch (error) {
            setError('Error al subir la imagen');
            setIsUpdating(false);
            return;
        }
    }

    const { error: proyectoError } = await supabase
        .from('proyectos')
        .insert([proyectoData]);

    if (proyectoError) {
        console.error('Error al insertar el proyecto:', proyectoError); // Muestra el error en la consola
        setError('Error al crear el proyecto');
        setIsUpdating(false);
        return;
    }

    const colaboradorData = {
      proyecto_key: proyectoData.key,
      usuario_id: user.id,
      perfil: 'admin'
      
    };

    console.log('Datos del colaborador:', colaboradorData); // Agrega este log para depurar

    const { error: colaboradorError } = await supabase
      .from('colaboradores')
      .insert([colaboradorData]);

    if (colaboradorError) {
      setError('Error al crear el colaborador');
      return;
    }

    
    // Enviar el correo de invitación solo si el correo no es null
    if (formData.correo) {
      try {
            // Nuevo código para insertar en la tabla 'invitados'
            const invitadoData = {
              email: formData.correo, // Usar el email del encargado
              invitado_por: user.id, // ID del usuario que crea el proyecto
              estado_invitacion: 'invitacion enviada', // Estado de la invitación
              proyecto_key: proyectoData.key, // Clave del proyecto
              perfil: 'encargado' // Perfil del invitado
            };
        
            const { error: invitadoError } = await supabase
              .from('invitados')
              .insert([invitadoData]);
        
            if (invitadoError) {
              setError('Error al crear el invitado');
              return;
            }

            await fetch('/.netlify/functions/sendEmailInvitacion', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.correo,
                    usuario: usuario, // Asegúrate de que estos campos existan
                    perfil: 'encargado',
                    proyecto: formData.proyecto,
                    key: proyectoData.key
                }),
            });
        } catch (error) {
            console.error('Error al enviar el correo de invitación:', error);
            setError('Error al enviar el correo de invitación');
            return;
        }
    }
    else{
      console.log('No se envió correo de invitación');
    }

    setIsUpdating(false); // Restablecer el estado al final
    navigate('/proyectos');
  };

  const handleCancel = () => {
    navigate('/proyectos');
  };

  const handleImageClick = () => {
    document.getElementById('imageInput').click(); // Abrir el cuadro de diálogo
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0];

      // Verificar si el tamaño de la imagen es mayor a 3MB
      if (selectedImage.size > 3 * 1024 * 1024) {
        setError('La imagen no debe superar los 3MB');
        return;
      }

      setImage(selectedImage);

      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  return (
    <>
      <h2 className='text-2xl font-bold mt-10 text-center'>Nuevo Proyecto</h2>
      <motion.div
        className='container-mayor px-4 sm:px-6 lg:px-8'
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }} // Animación de entrada para el formulario
      >
        <span className="material-symbols-outlined ico-cerrar-form" onClick={handleCancel}>
          cancel
        </span>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleSubmit} className="form-container space-y-6">
          <div className="flex flex-col sm:flex-row mb-10">
            <div className="image-upload w-full sm:w-1/3 flex flex-col items-center" onClick={handleImageClick}>
              <img 
                src={imageUrl || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/subir-logo.jpg'} 
                alt="Imagen del proyecto" 
                className="project-image" 
              />
              <label className='text-xs'>Imagen del proyecto</label>
              <input type="file" accept="image/*" id="imageInput" style={{ display: 'none' }} onChange={handleImageChange} />
            </div>
            <div className="description-container w-full sm:w-2/3 mt-4 sm:mt-0 sm:ml-2">
              <label className="text-left">Descripción del proyecto:</label>
              <textarea name="descripcion" value={formData.descripcion} onChange={handleChange} className="w-full" />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="text-left">Nombre Proyecto:</label>
              <input type="text" name="proyecto" value={formData.proyecto} onChange={handleChange} required className="w-full" />
            </div>

            <div>
              <label className="text-left">Ubicación:</label>
              {isApiLoaded && (
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  options={{
                    componentRestrictions: { country: 'CL' }
                  }}
                >
                  <input
                    type="text"
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value)}
                    name="ubicacion"
                    className="w-full"
                  />
                </Autocomplete>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="text-left">Fecha de Inicio:</label>
              <input type="date" name="fecha_inicio" value={formData.fecha_inicio} onChange={handleChange} className="w-full h-10 rounded p-2" />
            </div>
            <div>
              <label className="text-left">Fecha de Término:</label>
              <input type="date" name="fecha_termino" value={formData.fecha_termino} onChange={handleChange} className="w-full h-10 rounded p-2" />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="text-left">Nombre Encargado:</label>
              <input type="text" name="encargado" onChange={handleChange} className="w-full" />
            </div>
            <div>
              <label className="text-left">Correo Encargado:</label>
              <input type="email" name="correo" onChange={handleChange} className="w-full h-10 rounded p-2" />
            </div>
          </div>

          <div className="text-center">
            <p className="mt-10 font-bold">AGREGA EL LINK DE TU CARPETA DE PROYECTO</p>
            <p className="text-xs">Google Drive, Dropbox, OneDrive, ETC...</p>
            <br />
            <input type="url" name="url_carpeta" onChange={handleChange} className="w-full sm:w-80 h-10 rounded p-2" />
          </div>
          <button className="btn-azul w-full sm:w-auto" type="submit" disabled={isUpdating}>
            {isUpdating ? 'Creando Proyecto' : 'Crear'}
            {isUpdating && <span className="loading loading-dots loading-xs pt-4 ml-2"></span>}
          </button>
        </form>
      </motion.div>
    </>
  );
};

export default NuevoProyecto;
