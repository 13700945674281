import React from 'react'; // Añadido useEffect
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUsuario } from '../context/usuario'; // Asegúrate de importar el hook
import './Header.css';
import { supabase } from '../lib/supabaseClient'; // Asegúrate de importar tu cliente de Supabase

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { usuario } = useUsuario(); // Obtener y establecer el usuario del contexto



  const handleCloseDrawer = () => {
    const drawerToggle = document.getElementById('my-drawer-4');
    if (drawerToggle) {
      drawerToggle.checked = false;
    }

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  const handleLogout = async () => {
    // Cerrar sesión de Supabase
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error al cerrar sesión:', error.message);
      return;
    }

    navigate('/'); // Redirigir a la página principal después de cerrar sesión
  };

  return (
    <div className={`background-header`}>
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="navbar flex justify-between items-center">
          <div className="logo-container flex items-center justify-center relative">
            <Link to="/dashboard" onClick={handleCloseDrawer} className="text-xl normal-case animate-slideDown">
              <img className="logo" src="/img/logo-tubim.png" alt="Logo Tubim" />
            </Link>
            <label htmlFor="my-drawer-4" className="drawer-button lg:hidden absolute right-0 mr-4">
              <span className="material-symbols-outlined">menu</span>
            </label>
          </div>
          <div className="menu-header hidden lg:flex items-center">
            <div className="logo-info">
              <div className="logo-empresa-container">
                <Link to="/mis-datos" onClick={handleCloseDrawer}> {/* Añadido enlace a mis-datos */}
                  <img src={usuario?.url_logo || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-logo.jpg'} alt="Logo Grande" className="logo-empresa" />
                  <img src={usuario?.url_imagen || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-imagen.jpg'} alt="Logo Pequeño" className="logo-usuario" />
                </Link>
              </div>
              <div className="empresa-info text-left"> {/* Añadido 'text-left' para alinear a la izquierda */}
                <span className="nombre-empresa">{usuario?.empresa || 'Tu Empresa'}</span>
                <p>usuario</p>
                <span className="email-empresa">{usuario?.email || 'cargando email'}</span>
              </div>
            </div>
          
            <div className="links-container">
              <Link to="/proyectos" onClick={handleCloseDrawer} className={location.pathname === '/proyectos' ? 'active btn-header' : 'btn-header'}>
                <img src="/img/iconos/ico-proyectos.png" alt="Proyectos Icon" className="btn-icon" />
                Proyectos
                {location.pathname === '/proyectos' ? (
                  <span className="material-symbols-outlined">stat_minus_1</span>
                ) : (
                  <span className="material-symbols-outlined">chevron_right</span>
                )}
              </Link>
              <Link to="/reportes" onClick={handleCloseDrawer} className={location.pathname === '/reportes' ? 'active btn-header' : 'btn-header'}>
                <img src="/img/iconos/ico-reportes.png" alt="Reportes Icon" className="btn-icon" />
                Reportes
                {location.pathname === '/reportes' ? (
                  <span className="material-symbols-outlined">stat_minus_1</span>
                ) : (
                  <span className="material-symbols-outlined">chevron_right</span>
                )}
              </Link>
              <span className="material-symbols-outlined ml-8 cursor-pointer" onClick={handleLogout}>logout</span></div>
          </div>
        </div>
      </div>
      <div className="drawer drawer-end lg:hidden">
        <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
          <ul className="menu bg-base-200 text-base-content min-h-full w-80">
          
          <div className="logo-info">
              <div className="logo-empresa-container w-full flex justify-center pt-4">
                <Link to="/mis-datos" onClick={handleCloseDrawer} className="w-full flex justify-center">
                  <img src={usuario?.url_logo || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-logo.jpg'} alt="Logo Grande" className="logo-empresa w-full" />
                  <img src={usuario?.url_imagen || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-imagen.jpg'} alt="Logo Pequeño" className="logo-usuario w-full" />
                </Link>
              </div>
            
            <div className="empresa-info w-full text-left">
                <span className="nombre-empresa">{usuario?.empresa || 'Tu Empresa'}</span>
                <p>usuario</p>
                <span className="email-empresa">{usuario?.email || 'cargando email'}</span>
              </div>
            </div>
            <li><Link to="/proyectos" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/proyectos' ? 'active hover-underline' : 'hover-underline'}`}>Proyectos</Link></li>
            <li><Link to="/reportes" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/reportes' ? 'active hover-underline' : 'hover-underline'}`}>Reportes</Link></li>
            <li className="mt-auto text-left mt-4 ml-4"><button onClick={handleLogout}>Salir</button></li>
        </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
