import React from 'react';
import './PublicHeader.css'; // Asegúrate de crear y estilizar este archivo CSS
import { Link } from 'react-router-dom';

const PublicHeader = () => {
  return (
    <header className="header">
      
          
            <Link to="/" className="text-xl normal-case animate-slideDown">
              <img className="logo" src="/img/logo-tubim.png" alt="Logo Tubim" />
            </Link>
          
    </header>
  );
};

export default PublicHeader;