import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = useCallback(async () => {
        const { error } = await supabase.auth.signOut(); // Llama a signOut de Supabase
        if (error) {
            console.error('Error al cerrar sesión:', error.message);
        } else {
            navigate('/'); // Redirige a la página de inicio de sesión
        }
    }, [navigate]);

    React.useEffect(() => {
        handleLogout();
    }, [handleLogout]);

    return null; // No se renderiza nada en la interfaz
};

export default Logout;
