import React, { useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [telefono, setTelefono] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nacimiento, setNacimiento] = useState('');
  const [rut, setRut] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState(null);

  const handleImageClick = () => {
    document.getElementById('imageInput').click();
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0];
      
      // Verificar el tamaño del archivo (3 MB = 3 * 1024 * 1024 bytes)
      if (selectedImage.size > 3 * 1024 * 1024) {
        setError('La imagen no debe exceder los 3 MB.');
        return;
      }

      setImage(selectedImage);
      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  const handleLogoClick = () => {
    document.getElementById('LogoInput').click();
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedLogo = e.target.files[0];
      
      // Verificar el tamaño del archivo (3 MB = 3 * 1024 * 1024 bytes)
      if (selectedLogo.size > 3 * 1024 * 1024) {
        setError('El logo no debe exceder los 3 MB.');
        return;
      }

      setLogo(selectedLogo);
      const logoUrl = URL.createObjectURL(selectedLogo);
      setLogoUrl(logoUrl);
    }
  };

  const handleCelularFocus = () => {
    if (!telefono) {
      setTelefono('+56');
    }
  };

  const handleCelularBlur = () => {
    if (telefono === '' || telefono === '+56') {
      setTelefono(null);
    }
  };

  const isValidName = (name) => {
    if (!name) return false;
    const regex = /^[A-Za-zÀ-ÿ\s]+$/;
    return name.length >= 3 && regex.test(name);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const username = email.split('@')[0];

    if (telefono.length !== 12) {
      setError('Celular no válido');
      return;
    }

    if (!isValidName(nombre)) {
      setError('El nombre debe tener al menos 3 caracteres y no contener números ni caracteres especiales.');
      return;
    }

    if (!isValidName(apellido)) {
      setError('El apellido debe tener al menos 3 caracteres y no contener números ni caracteres especiales.');
      return;
    }

    if (password !== repeatPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    setIsSubmitting(true);

    try {
      // Subir imagen de perfil si existe, de lo contrario usar URL predeterminada
      let imageUrlToUpdate = 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/subir-imagen.jpg';
      if (image) {
        const fileExt = image.name.split('.').pop();
        const fileName = `usuario-${username}-${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;

        const { error: uploadError } = await supabase
          .storage
          .from('usuarios')
          .upload(filePath, image);

        if (uploadError) {
          throw uploadError;
        }

        imageUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/${filePath}`;
        setImageUrl(imageUrlToUpdate);
      }

      // Subir logo de empresa si existe, de lo contrario usar URL predeterminada
      let logoUrlToUpdate = 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/subir-logo.jpg';
      if (logo) {
        const fileExt = logo.name.split('.').pop();
        const fileName = `logo-${username}-${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;

        const { error: uploadError } = await supabase
          .storage
          .from('usuarios')
          .upload(filePath, logo);

        if (uploadError) {
          throw uploadError;
        }

        logoUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/${filePath}`;
        setLogoUrl(logoUrlToUpdate);
      }

      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      }, {
        redirectTo: 'https://tu-sitio.com/dashboard' // Cambia esta URL por la de tu dashboard
      });

      if (signUpError) {
        setError(signUpError.message);
        setIsSubmitting(false);
        return;
      }

      if (!data || !data.user) {
        setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
        setIsSubmitting(false);
        return;
      }

      const user = data.user;

      const userData = {
        user_id: user.id,
        email: user.email,
        nombre,
        apellido,
        empresa,
        celular: telefono,
        username: email.split('@')[0],
        url_imagen: imageUrlToUpdate, // Guardar URL de la imagen en el campo correcto
        url_logo: logoUrlToUpdate,    // Guardar URL del logo en el campo correcto
      };

      if (nacimiento) {
        userData.nacimiento = nacimiento;
      }
      if (rut) {
        userData.rut = rut;
      }

      const { error: insertError } = await supabase
        .from('usuarios')
        .insert([userData]);

      if (insertError) {
        console.error(insertError);
        setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
        setIsSubmitting(false);
        return;
      }

      setMessage('Registro exitoso, por favor verifica tu correo electrónico.');
      setEmail('');
      setPassword('');
      setRepeatPassword('');
      setNombre('');
      setApellido('');
      setEmpresa('');
      setTelefono('');

      navigate('/esperando-validacion');
    } catch (error) {
      console.error(error);
      setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="container-principal">
        <div className="profile-image-container" onClick={handleImageClick}>
          <img src={imageUrl || 'img/subir-imagen.jpg'} alt="Profile" className="profile-image" />
        </div>
        <input
          type="file"
          id="imageInput"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
        <form onSubmit={handleRegister} autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col items-start">
              <label htmlFor="nombre" className="mb-1">Nombre:</label>
              <input
                id="nombre"
                type="text"
                placeholder="Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
                className="w-full"
                autoComplete="off"
                name="nombre"
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="apellido" className="mb-1">Apellido:</label>
              <input
                id="apellido"
                type="text"
                placeholder="Apellido"
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
                required
                className="w-full"
                autoComplete="off"
                name="apellido"
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="telefono" className="mb-1">Celular:</label>
              <input
                id="telefono"
                type="text"
                placeholder="Celular"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                required
                className="w-full"
                autoComplete="off"
                name="telefono"
                onFocus={handleCelularFocus}
                onBlur={handleCelularBlur}
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="email" className="mb-1">Correo electrónico:</label>
              <input
                id="email"
                type="email"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full"
                autoComplete="new-email"
                name="new-email"
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="nacimiento" className="mb-1">Fecha Nacimiento:</label>
              <input
                id="nacimiento"
                type="date"
                value={nacimiento}
                onChange={(e) => setNacimiento(e.target.value)}
                placeholder="dd-mm-aaaa"
                className="w-full"
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="rut" className="mb-1">RUT:</label>
              <input
                id="rut"
                type="text"
                value={rut}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[0-9kK-]*$/.test(value)) {
                    setRut(value);
                  }
                }}
                className="w-full"
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="password" className="mb-1">Clave:</label>
              <input
                id="password"
                type="password"
                placeholder="Clave"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full"
                autoComplete="new-password"
                name="password"
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="repeatPassword" className="mb-1">Repetir Clave:</label>
              <input
                id="repeatPassword"
                type="password"
                placeholder="Repetir Clave"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                required
                className="w-full"
                autoComplete="new-password"
                name="repeatPassword"
              />
            </div>
          </div>

          <div className="divider mt-20"></div>

          <div className="profile-image-container" onClick={handleLogoClick}>
            <img src={logoUrl || 'img/subir-logo.jpg'} alt="Logo" className="profile-logo" />
            <label className="profile-logo-label">Logo de empresa</label>
          </div>
          <input
            type="file"
            id="LogoInput"
            style={{ display: 'none' }}
            onChange={handleLogoChange}
          />

          <div className="flex flex-col items-start">
            <label htmlFor="empresa" className="mb-1">Empresa:</label>
            <input
              id="empresa"
              type="text"
              placeholder="Empresa"
              value={empresa}
              onChange={(e) => setEmpresa(e.target.value)}
              required
              className="w-full"
              autoComplete="off"
              name="empresa"
            />
          </div>

          {error && <p className="text-red-500 text-xs italic mt-4">{error}</p>}
          {message && <p className="text-green-500 text-xs italic mt-4">{message}</p>}
          <br /><br />
          <button
            type="submit"
            disabled={isSubmitting}
            className="mt-30 btn-azul"
          >
            {isSubmitting ? 'Registrando...' : 'Crear Cuenta'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;
