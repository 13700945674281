import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';

const CrearColaborador = () => {
  const { clienteRut } = useParams();
  const [email, setEmail] = useState('');
  const [perfil, setPerfil] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [perfiles, setPerfiles] = useState([]);
  const { user, usuario } = useUsuario(); // Obtener el usuario desde el contexto

  const sendEmailInvitacion = async (email, cliente, usuario, perfil) => {
    console.log(email, cliente, usuario, perfil);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/.netlify/functions/sendEmailInvitacion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, cliente, usuario, perfil }), // Incluir perfil en el cuerpo del mensaje
      });

      if (!response.ok) {
        console.log('Error en el envío del correo de confirmación:', response);
        const responseData = await response.json();
        throw new Error(responseData.error || 'Error al enviar el correo de confirmación.');
      }
    } catch (error) {
      console.error('Error en el proceso de registro:', error);
    }
  };

  useEffect(() => {
    const fetchPerfiles = async () => {
      const { data, error } = await supabase
        .from('perfiles')
        .select('*');

      if (error) {
        setError(error.message);
      } else {
        setPerfiles(data.filter(perfil => perfil.perfil)); // Filtrar perfiles vacíos
      }
    };

    fetchPerfiles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Obtener el modelo completo del cliente
      const { data: clienteData, error: clienteError } = await supabase
        .from('clientes')
        .select('*')
        .eq('rut', clienteRut)
        .single();

      if (clienteError) {
        throw clienteError;
      }

      // Verificar si el email existe en la tabla usuarios
      const { data: usuarios, error: userError } = await supabase
        .from('usuarios')
        .select('user_id')
        .eq('email', email);

      if (userError) {
        throw userError;
      }

      if (usuarios.length === 0) {
        // Si el email no existe, preguntar si desea invitarlo
        const invite = window.confirm('El email no existe. ¿Desea invitar a este usuario?');
        if (!invite) {
          return;
        }

        // Insertar un registro en la tabla invitados
        const { error: inviteError } = await supabase
          .from('invitados')
          .insert([{ email, invitado_por: user.id, estado_invitacion: 'invitacion enviada', cliente_rut: clienteRut, perfil }]); // Añadir perfil

        if (inviteError) {
          throw inviteError;
        }

        // Enviar correo de invitación
        await sendEmailInvitacion(email, clienteData, usuario, perfil); // Pasar el modelo completo del cliente y el perfil

        // Mostrar mensaje de invitación enviada y redirigir al cliente
        alert('Invitación enviada');
        navigate(`/cliente/${clienteRut}`);
        return;
      }

      // Si el email existe, obtener el user_id
      const usuarioExistente = usuarios[0].user_id;

      const { error: insertError } = await supabase
        .from('colaboradores')
        .insert([{ cliente_rut: clienteRut, usuario_id: usuarioExistente, perfil }]);

      if (insertError) {
        throw insertError;
      }

      navigate(`/cliente/${clienteRut}`);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Agregar Colaborador</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="perfil">
            Perfil
          </label>
          <select
            id="perfil"
            value={perfil}
            onChange={(e) => setPerfil(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Seleccione un perfil</option>
            {perfiles.map((perfil) => (
              <option key={perfil.id} value={perfil.perfil}>
                {perfil.perfil}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Agregar
          </button>
          <button
            type="button"
            onClick={() => navigate(`/cliente/${clienteRut}`)}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default CrearColaborador;