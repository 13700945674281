import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import { useUsuario } from '../context/usuario';
import './MyAccount.css';
import Skeleton from './MyAccountSkeleton'; // Asegúrate de importar tu componente de skeleton


const MyAccount = () => {
  const { setUsuario } = useUsuario();
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [rut, setRut] = useState('');
  const [name, setName] = useState(''); // Asegúrate de que el valor inicial sea una cadena vacía
  const [lastName, setLastName] = useState(''); // Asegúrate de que el valor inicial sea una cadena vacía
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [imageUrlFija, setImageUrlFija] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoUrlFija, setLogoUrlFija] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false); // Nuevo estado para controlar la carga de datos
  const [error, setError] = useState(''); // Agrega esta línea para manejar el estado del error
  const [updateMessage, setUpdateMessage] = useState(''); // Nuevo estado para el mensaje de actualización
  const navigate = useNavigate();
  
  const isValidName = (name) => {
    if (!name) return false; // Agrega esta línea para manejar null o undefined
    const regex = /^[A-Za-zÀ-ÿ\s]+$/; // Permite letras y espacios
    return name.length >= 3 && regex.test(name);
  };

  const handleCelularFocus = () => {
    if (!celular) {
      setCelular('+56'); // Establece el valor inicial al hacer foco
    }
  };

  const handleCelularBlur = () => {
    if (celular === '' || celular === '+56') {
      setCelular(null); // Restablece a null si el campo está vacío o solo tiene el prefijo
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
          throw error;
        }
        if (user) {
          setUser(user);

          const { data: userData } = await supabase
            .from('usuarios')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (userData) {
            setUsername(userData.username);
            setBirthdate(userData.nacimiento);
            setRut(userData.rut);
            setName(userData.nombre);
            setLastName(userData.apellido);
            setCelular(userData.celular);
            setEmail(userData.email);
            setImageUrl(userData.url_imagen);
            setImageUrlFija(userData.url_imagen);
            setLogoUrl(userData.logo);
            setLogoUrlFija(userData.logo);
            setEmpresa(userData.empresa);
            console.log("userData", userData);
            setDataLoaded(true); // Establece dataLoaded a true después de cargar los datos
          }

        } else {
          console.log('No user signed in');
        }
      } catch (error) {
        console.error('Error fetching user:', error.message);
      }
    };

    fetchUserData();
  }, []);


  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    // Validación de celular
    if (celular.length !== 12) {
      setError('Celular no válido'); // Mensaje de error
      return;
    }

    // Validación de nombre y apellido solo si se han ingresado
    if (name && !isValidName(name)) {
      setError('El nombre debe tener al menos 3 caracteres y no contener números ni caracteres especiales.'); // Actualiza el estado del error
      return;
    }

    if (lastName && !isValidName(lastName)) {
      setError('El apellido debe tener al menos 3 caracteres y no contener números ni caracteres especiales.'); // Actualiza el estado del error
      return;
    }

    if (!user) {
      console.error('No user signed in');
      return;
    }

    setIsUpdating(true);

    try {
      let imageUrlToUpdate = imageUrl;
      let logoUrlToUpdate = logoUrl;

      if (image) {
        if (imageUrl !== imageUrlFija) {
          const url = new URL(imageUrlFija);
          const pathArray = url.pathname.split('/');
          const oldFilePath = pathArray.slice(pathArray.indexOf('usuarios') + 1).join('/');
          const nombreArchivo = pathArray[pathArray.length - 1];
          if (oldFilePath && nombreArchivo !== 'subir-imagen.jpg') {
            const { error: removeError } = await supabase.storage.from('usuarios').remove([oldFilePath]);
            if (removeError) {
              console.error('Error al eliminar la imagen del storage:', removeError.message);
              throw removeError;
            }
            console.log('Imagen eliminada del storage', oldFilePath);
          }

          const fileExt = image.name.split('.').pop();
          const fileName = `${user.id}/${Date.now()}.${fileExt}`;
          const filePath = `${fileName}`;

          const { error: uploadError } = await supabase
            .storage
            .from('usuarios')
            .upload(filePath, image);

          if (uploadError) {
            throw uploadError;
          }

          imageUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/${filePath}`;
          setImageUrl(imageUrlToUpdate); // Actualiza la URL de la imagen aquí
        }

      }

      if (logo) {
        if (logoUrl !== logoUrlFija) {
          if (logoUrlFija) {
            const url = new URL(logoUrlFija);
            const pathArray = url.pathname.split('/');
            const oldFilePath = pathArray.slice(pathArray.indexOf('usuarios') + 1).join('/');
            const nombreArchivo = pathArray[pathArray.length - 1];
            if (oldFilePath && nombreArchivo !== 'subir-logo.jpg') {
              const { error: removeError } = await supabase.storage.from('usuarios').remove([oldFilePath]);
              if (removeError) {
                console.error('Error al eliminar la imagen del storage:', removeError.message);
                throw removeError;
              }
            }

            const fileExt = logo.name.split('.').pop();
            const fileName = `${user.id}/${Date.now()}.${fileExt}`;
            const filePath = `${fileName}`;

            const { error: uploadError } = await supabase
              .storage
              .from('usuarios')
              .upload(filePath, logo);

            if (uploadError) {
              throw uploadError;
            }

            logoUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/${filePath}`;
            setLogoUrl(logoUrlToUpdate);
          }
        }

      }

      const updatedUserData = {
        username,
        nacimiento: birthdate,
        rut,
        nombre: name,
        apellido: lastName,
        celular: celular,
        url_imagen: imageUrlToUpdate,
        logo: logoUrlToUpdate,
        empresa,
        email: email, // Asegúrate de incluir el email aquí
      };

      const { error } = await supabase
        .from('usuarios')
        .update(updatedUserData)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error updating profile:', error.message);
      } else {
        setImageUrlFija(imageUrlToUpdate);
        setImage(null);
        setLogoUrlFija(logoUrlToUpdate);
        setLogo(null);

        setIsUpdating(false);
        setUsuario(updatedUserData);

        setError(''); // Limpia el error si la actualización es exitosa

        // Establece el mensaje de actualización y restablece después de 3 segundos
        setUpdateMessage('Datos actualizados');
        setTimeout(() => {
          setUpdateMessage('');
          navigate('/dashboard');
        }, 3000);
      }
    } catch (error) {
      console.error('Error updating profile:', error.message);
      setIsUpdating(false);
    }
  };

  const handleImageClick = () => {
    document.getElementById('imageInput').click();
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);

      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  const handleLogoClick = () => {
    document.getElementById('LogoInput').click();
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedLogo = e.target.files[0];
      setLogo(selectedLogo);

      const logoUrl = URL.createObjectURL(selectedLogo);
      setLogoUrl(logoUrl);
    }
  };

  return (
    <div className="container mx-auto p-4">
      {!dataLoaded ? ( // Muestra el skeleton mientras se cargan los datos
        <Skeleton />
      ) : (
        <div className="container-principal">
          <div className="profile-image-container" onClick={handleImageClick}>
            <img src={imageUrl || 'img/subir-imagen.jpg'} alt="Profile" className="profile-image" />
          </div>
          <input
            type="file"
            id="imageInput"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <form onSubmit={handleUpdateProfile}>
            <div className="form-row">
              <div>
                <label className="label-izquierda">Nombre:</label>
                <input type="text" value={name || ''} onChange={(e) => setName(e.target.value)} />
              </div>
              <div>
                <label className="label-izquierda">Apellido:</label>
                <input type="text" value={lastName || ''} onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>
            <div className="form-row">
              <div>
                <label className="label-izquierda">Celular:</label>
                <input 
                  type="text" 
                  value={celular || ''} // Asegúrate de que el valor sea una cadena vacía si es null
                  onFocus={handleCelularFocus} // Agrega el manejador de foco
                  onBlur={handleCelularBlur} // Agrega el manejador de blur
                  onChange={(e) => {
                    const value = e.target.value;
                    // Permite solo números y el prefijo +56
                    if (value === '' || (/^\+56[0-9]*$/.test(value) && value.length <= 12)) {
                      setCelular(value); // Permite borrar completamente
                    }
                  }} 
                />
              </div>
              <div>
                <label className="label-izquierda">Correo electrónico:</label>
                <input type="text" value={email} readOnly />
              </div>
            </div>
            <div className="form-row">
              <div>
                <label className="label-izquierda">Fecha Nacimiento:</label>
                <input 
                  type="date" 
                  value={birthdate} 
                  onChange={(e) => setBirthdate(e.target.value)} 
                  placeholder="dd-mm-aaaa" 
                />
              </div>
              <div>
                <label className="label-izquierda">RUT:</label>
                <input
                  type="text"
                  value={rut}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9kK-]*$/.test(value)) {
                      setRut(value);
                    }
                  }}
                />
              </div>
            </div>

            <div className="divider mt-20"></div>

            <div className="profile-image-container" onClick={handleLogoClick}>
              <img src={logoUrl || 'img/subir-logo.jpg'} alt="Profile" className="profile-logo" />
              <label className="profile-logo-label">Logo de empresa</label>
            </div>
            <input
              type="file"
              id="LogoInput"
              style={{ display: 'none' }}
              onChange={handleLogoChange}
            />

            <div className="form-row">
              <div>
                <label className="label-izquierda">Empresa:</label>
                <input type="text" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
              </div>
            </div>
            <br />
            {error && <p className="error text-red-400">{error}</p>}
            <button 
              className="btn-azul" 
              type="submit" 
              disabled={isUpdating} 
              style={{ 
                backgroundColor: updateMessage ? 'var(--color-atention)' : 'var(--main-color)', 
                transition: 'background-color 0.3s ease, color 0.3s ease' // Agrega la transición
              }}
            >
              {isUpdating ? 'Actualizando' : updateMessage ? (
                <>
                  {updateMessage} 
                  <span className="material-symbols-outlined">check</span> {/* Ícono al lado del mensaje */}
                </>
              ) : 'Guardar'}
              {isUpdating && <span className="loading loading-dots loading-xs"></span>}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MyAccount;
