import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importar useNavigate
import { supabase, supabaseAdmin } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';
import './Proyectos.css';
import { motion } from 'framer-motion'; // Importar motion
import ProyectosSkeleton from './ProyectosSkeleton'; // Importar el componente ProyectosSkeleton

const Proyectos = () => {
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // Estado para el orden
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const { user } = useUsuario(); // Obtener el usuario desde el contexto
  const navigate = useNavigate(); // Inicializar useNavigate
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Estado para el cuadro de diálogo
  const [projectToDelete, setProjectToDelete] = useState(null); // Estado para el proyecto a eliminar
  const [reportCount, setReportCount] = useState(0); // Estado para contar reportes
  const [isDeleting, setIsDeleting] = useState(false); // Estado para manejar la eliminación

  useEffect(() => {
    const fetchProyectos = async () => {
      try {
        // Obtener los clientes a los que el usuario tiene acceso, incluyendo el perfil
        const { data: colaboradoresData, error: colaboradoresError } = await supabase
          .from('colaboradores')
          .select('proyecto_key, perfil')
          .eq('usuario_id', user.id);

        if (colaboradoresError) {
          setError(colaboradoresError.message);
          setLoading(false);
          return;
        }

        const proyectosKeys = colaboradoresData.map(colaborador => colaborador.proyecto_key);

        // Obtener los proyectos de los clientes a los que el usuario tiene acceso
        const { data: proyectosData, error: proyectosError } = await supabase
          .from('proyectos')
          .select('*')
          .in('key', proyectosKeys);

        if (proyectosError) {
          setError(proyectosError.message);
          setLoading(false);
          return;
        }

        const proyectosWithPerfil = proyectosData.map(proyecto => {
          const colaborador = colaboradoresData.find(c => c.proyecto_key === proyecto.key);
          return {
            ...proyecto,
            perfil: colaborador ? colaborador.perfil : null, // Añadir el perfil al proyecto
          };
        });

        setProyectos(proyectosWithPerfil);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    if (user) {
      fetchProyectos();
    }
  }, [user]);

  useEffect(() => {
    const checkAdminStatusForProjects = async () => {
      if (user) {
        const { data: colaboradoresData, error } = await supabase
          .from('colaboradores')
          .select('proyecto_key, perfil')
          .eq('usuario_id', user.id);

        if (error) {
          console.error('Error al obtener el perfil del usuario:', error.message);
          return;
        }

        // Crear un objeto que mapea el proyecto_key a su estado de admin
        const adminStatus = {};
        colaboradoresData.forEach(colaborador => {
          adminStatus[colaborador.proyecto_key] = colaborador.perfil === 'admin';
        });

        // setAdminProjects(adminStatus); // Eliminar esta línea si no es necesaria
      }
    };

    checkAdminStatusForProjects();
  }, [user]);

  const sortedProyectos = [...proyectos].sort((a, b) => {
    return sortOrder === 'asc' ? a.reportCount - b.reportCount : b.reportCount - a.reportCount;
  });

  const filteredProyectos = sortedProyectos.filter((proyecto) => {
    return (
      proyecto.proyecto.toLowerCase().includes(searchTerm.toLowerCase()) ||
      proyecto.descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleDeleteProject = async (projectId) => {
    setIsDeleting(true); // Activar el estado de eliminación
    try {
      // 1. Obtener todos los reportes asociados al proyecto
      const { data: reportesData, error: reportesError } = await supabaseAdmin
        .from('reportes')
        .select('key')
        .eq('proyecto_key', projectId);
        if (reportesError) throw new Error(reportesError.message);
        
        const reportKeys = reportesData.map(reporte => reporte.key);
        // console.log("Reportes asociados al proyecto:", reportKeys);
        
        // console.log("Eliminando invitados asociados al proyecto:", projectId);
        await supabaseAdmin
        .from('invitados')
        .delete()
        .eq('proyecto_key ', projectId);

        // console.log("Eliminando colaboradores asociados al proyecto:", projectId);
        await supabaseAdmin
          .from('colaboradores')
          .delete()
          .eq('proyecto_key', projectId);
          
          // console.log("Eliminando chats asociados al proyecto:", projectId);
          await supabaseAdmin
          .from('chat')
          .delete()
          .eq('proyecto', projectId);
          
      // 2. Borrar archivos y links asociados a los reportes
      
      if (reportKeys.length > 0) {
        // Borrar archivos asociados a los reportes
        // console.log("Eliminando archivos asociados a los reportes:", reportKeys);
        await supabaseAdmin
          .from('archivos')
          .delete()
          .in('reporte_key', reportKeys);

        // console.log("Eliminando links asociados a los reportes:", reportKeys);
        await supabaseAdmin
          .from('links')
          .delete()
          .in('reporte_key', reportKeys);
          
          
          
        // console.log("Eliminando reportes asociados al proyecto:", reportKeys);
        await supabaseAdmin
        .from('reportes')
        .delete()
        .in('key', reportKeys);



      }
      

      // Paso 1: Definir la ruta de la carpeta
      const folderPath = `${projectId}/`; // Cambiado para definir la ruta de la carpeta

      // Imprimir la ruta que se está utilizando
      // console.log("Buscando archivos en la ruta:", folderPath);

      // Listar todos los archivos en la carpeta
      const { data: files, error: listError } = await supabaseAdmin
        .storage
        .from('proyectos')
        .list(folderPath); // Usar la ruta definida

      if (listError) {
        console.error('Error listing files:', listError);
      } else {
        // console.log("Archivos en la carpeta del proyecto:", files); // Agregado para depuración
        // Paso 2: Obtener los nombres de los archivos
        const filePaths = files.map(file => file.name);
        
        if (filePaths.length > 0) { // Verificar que hay archivos para eliminar
          // console.log("Eliminando archivos del almacenamiento:", filePaths);
          const { error: storageError } = await supabaseAdmin
            .storage
            .from('proyectos')
            .remove(filePaths.map(file => `${folderPath}${file}`)); // Eliminar los archivos listados con la ruta completa

          if (storageError) {
            console.error('Error removing files:', storageError);
          } else {
            console.log("Archivos eliminados exitosamente:", filePaths); // Confirmación de éxito
          }
        } else {
          console.log('No hay archivos para eliminar en la carpeta.');
        }
      }

      const { error: deleteError } = await supabase
      .from('proyectos')
      .delete()
      .eq('key', projectId);

      if (deleteError) {
        console.error('Error al eliminar el proyecto:', deleteError.message);
        setError(deleteError.message);
        return;
      }

      // eliminar la carpeta del proyecto
      // console.log("Eliminando carpeta del proyecto en storage:");
      const { error: storageError } = await supabaseAdmin
      .storage
      .from('proyectos')
      .remove([`${projectId}`]); 
      
      if (storageError) { // Manejo de error
        console.error('Error al eliminar la carpeta del almacenamiento:', storageError.message);
        setError(storageError.message); // Manejar el error mostrando un mensaje
        return; // Salir si hay un error al eliminar la carpeta
      } else {
        // console.log("Carpeta eliminada exitosamente del almacenamiento."); // Confirmación de éxito
      }

      // eliminar el proyecto de la base de datos

      

      if (error) {
        setError(error.message);
      } else {
        // Actualizar la lista de proyectos después de la eliminación
        setProyectos(prevProyectos => prevProyectos.filter(proyecto => proyecto.key !== projectId)); // Cambiado de id a key
      }
      setIsDialogOpen(false); // Cerrar el cuadro de diálogo
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDeleting(false); // Desactivar el estado de eliminación al finalizar
    }
  };

  const openDialog = (proyecto) => {
    setProjectToDelete(proyecto.key);
    setReportCount(proyecto.reportCount); // Establecer el conteo de reportes
    setIsDialogOpen(true);
  };

  const handleRemoveUserFromProject = async (projectKey) => {
    try {
      const { error } = await supabase
        .from('colaboradores')
        .delete()
        .eq('proyecto_key', projectKey)
        .eq('usuario_id', user.id); // Asegúrate de eliminar solo el registro del usuario actual

      if (error) {
        console.error('Error al eliminar el perfil del proyecto:', error.message);
        setError(error.message);
      } else {
        // Actualizar la lista de proyectos después de la eliminación del perfil
        setProyectos(prevProyectos => prevProyectos.map(proyecto => {
          if (proyecto.key === projectKey) {
            return {
              ...proyecto,
              perfil: null, // Eliminar el perfil del usuario del proyecto
            };
          }
          return proyecto;
        }));
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) return <ProyectosSkeleton />; // Mostrar el skeleton mientras se cargan los datos

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 mt-10 mb-20">
      <motion.div 
        className="relative w-full" 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5 }} // Animación de entrada
      >
        <h1 className="text-3xl font-bold text-center">Listado de Proyectos</h1>
        <div className="btn-volver">
          <div className="flex items-center" onClick={() => navigate('/dashboard')}>
            <div className="back circular border">
              <span className="material-symbols-outlined">arrow_back</span>
            </div>
            <span className="text-xs cursor-pointer">Volver</span>
          </div>
        </div>
      </motion.div>
      <div className="mb-4 flex justify-center"> {/* Centrado del botón */}
        <button
          onClick={() => navigate('/proyectos/nuevo-proyecto')} // Navegar a NuevoProyecto
          className="bg-[var(--color-atention)] text-white px-6 py-5 rounded-2xl flex items-center mt-12" // Cambiado el color del botón
        >
          <span className="material-symbols-outlined mr-2">add_circle</span>
          PROYECTO {/* Texto actualizado */}
        </button>
      </div>
      {proyectos.length > 0 ? (
        <>
          <div className="mb-4 flex justify-center items-center mt-12"> {/* Contenedor flex para alinear verticalmente */}
            <input
              type="text"
              placeholder="Buscar proyecto"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border p-3 rounded w-full max-w-[400px] text-[#3A3A3A] input-mobile" // Limitar ancho a 300px y color de texto
            />
            <select
              onChange={(e) => setSortOrder(e.target.value)} // Cambiado a un combobox
              className="text-[#3A3A3A] p-3 rounded ml-2 select-mobile" // Cambiado el color del texto
            >
              <option value="reportCountAsc">Ordenar por cantidad de reportes (Ascendente)</option>
              <option value="reportCountDesc">Ordenar por cantidad de reportes (Descendente)</option>
              <option value="fechaCreacionAsc">Ordenar por fecha de creación (Ascendente)</option>
              <option value="fechaCreacionDesc">Ordenar por fecha de creación (Descendente)</option>
              <option value="calificacionAsc">Ordenar por calificación (Ascendente)</option>
              <option value="calificacionDesc">Ordenar por calificación (Descendente)</option>
            </select>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center mt-12">
          <div className="bg-gray-200 p-10 text-center w-4/5 rounded-2xl">
            <h2 className="text-xl font-bold mb-4 text-[var(--color-atention)]">PROYECTOS</h2>
              <p className="text-xl text-[var(--secondary-background)] text-justify pb-10">
                En esta ventana encontrarás todos los proyectos que desarrollas con tus empresas Partners. Aquí podrás acceder de manera rápida y organizada a toda la información relevante de cada proyecto, facilitando el seguimiento y la colaboración. Toda la documentación, reportes BIM, puntuación de proyecto, avances y datos relacionados estarán centralizados para que puedas consultarlos fácilmente, optimizando la gestión y el flujo de trabajo en cada una de tus iniciativas conjuntas.
              </p>
          </div>
        </div>
      )}
      <div className="flex flex-wrap justify-center mt-12">
        {filteredProyectos.map((proyecto) => (
          <motion.div 
            key={proyecto.id} 
            className="max-w-[380px] bg-white rounded-2xl shadow-md overflow-hidden flex flex-col relative m-1 mb-4 md:m-6"
            initial={{ scale: 0 }} 
            animate={{ scale: 1 }} 
            transition={{ duration: 0.3 }} // Animación de entrada para cada proyecto
          >
            <div className="absolute top-1 right-1 md:top-4 md:right-4 text-gray-500 z-10">
              {proyecto.perfil && (
                <span className="text-sm font-bold mr-16">{proyecto.perfil}</span>
              )}
            </div>
            <button 
              className="absolute top-1 md:top-4 right-1 md:right-4 text-gray-500 hover:text-gray-800 z-10" 
              onClick={() => {
                if (proyecto.perfil === 'admin') {
                  openDialog(proyecto); // Si es admin, abrir el diálogo para eliminar el proyecto
                } else {
                  handleRemoveUserFromProject(proyecto.key); // Si no es admin, eliminar su perfil del proyecto
                }
              }}
            >
              <span className="material-symbols-outlined text-4xl">cancel</span>
            </button>
            <div className="flex flex-1 mitad-arriba mt-1 md:mt-4 ml-1 md:ml-2">
              <div className="relative w-20 h-20 md:w-24 md:h-24 m-2 flex-shrink-0"> {/* Cambiado a w-20 h-20 */}
                <img 
                  src={proyecto.url_imagen || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-logo.jpg'} 
                  alt={proyecto.proyecto} 
                  className="rounded-2xl w-full h-full object-cover"
                />
                <div className="flex items-center justify-center mb-2 mt-2">
                  <img src="/img/iconos/ico-reportes.png" alt="Reportes" className="w-5 h-5 opacity-60" />
                  <p className="ml-2 text-gray-600">{proyecto.reportCount}</p>
                </div>
              </div>
              <div className="flex flex-col datos-proyecto ml-1 md:ml-2 mt-1 md:mt-4">
                <h2 className="text-m md:text-xl font-bold pt-4" style={{ color: '#3D3D3C' }}>{proyecto.proyecto}</h2>
                <p className="text-gray-600" style={{ minHeight: '40px' }}> {/* Ajusta la altura mínima según sea necesario */}
                  {proyecto.descripcion && proyecto.descripcion.length > 0 
                    ? (proyecto.descripcion.length > 22 
                        ? `${proyecto.descripcion.substring(0, 22)}...` 
                        : proyecto.descripcion) 
                    : ' ' // Espacio en blanco para mantener la posición
                  }
                </p>
                {proyecto.correo && ( // Mostrar solo si existe el correo
                  <div className="flex items-center mt-6">
                    {proyecto.estado_invitacion === 'invitacion aceptada' ? (
                      <div className="tooltip tooltip-primary" data-tip={`Invitación aceptada el ${new Date(proyecto.fecha_aceptacion).toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}`}>
                        <span className="material-symbols-outlined mr-1 md:mr-2" style={{ color: '#4BA9D3' }}>mail</span>
                      </div>
                    ) : proyecto.estado_invitacion === 'invitacion enviada' ? (
                      <div className="tooltip tooltip-primary" data-tip="Aún no acepta la invitacion">
                        <span className="loading loading-spinner loading-xs mr-2"></span>
                      </div>
                    ) : null}
                      <p className="text-gray-400 text-sm md:text-base">{proyecto.correo}</p>
                  </div>
                )}
                {proyecto.celular && ( // Mostrar solo si existe el celular
                  <div className="flex items-center mt-3">
                    <span className="material-symbols-outlined mr-2" style={{ color: '#4BA9D3' }}>phone_iphone</span>
                    <p className="text-gray-400">{proyecto.celular}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="p-4 flex-1 mitad-abajo mt-10">
              <div className="flex items-center mb-2 w-4/5 bg-[#D9E0E5] border border-[#CCC9C9] p-4 rounded-lg link-proyecto">
                <span className="material-symbols-outlined text-4xl md:text-6xl opacity-60">settings</span>
                <Link to={`/proyecto/${proyecto.key}`} className="ml-4 md:ml-8 px-1 py-2 rounded text-sm md:text-lg line-height-1 font-bold">VER / EDITAR / AGREGAR REPORTES</Link>
              </div>
              <div className="flex items-end justify-start mt-8">
                <div className="flex items-center ml-2 md:ml-10">
                  <a href={proyecto.url_carpeta} target="_blank" rel="noopener noreferrer" className="flex items-center">
                    <span className="material-symbols-outlined text-4xl md:text-5xl">folder</span>
                    <p className="ml-2 text-sm md:text-base">Carpeta <br /> de proyecto</p>
                  </a>
                </div>
                <div className="flex items-center ml-5 md:ml-10">
                  <span className="material-symbols-outlined text-3xl md:text-4xl text-[#EBA720]">star</span>
                  <p className="ml-2 text-xl md:text-2xl font-bold text-[#3A3A3A]">{proyecto.promedioNotas}</p>
                </div>
                <Link to={`/proyectos/editar-proyecto/${proyecto.key}`} 
                  className="material-symbols-outlined circular absolute right-2 md:right-4 bottom-2 md:bottom-4"
                >
                  edit
                </Link>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      {isDialogOpen && (
        <dialog open className="modal fixed inset-0 flex items-center justify-center">
          <div className="modal-box">
            <h3 className="text-lg font-bold text-center mb-4">ADVERTENCIA</h3>
            <p>El proyecto <strong>{proyectos.find(p => p.key === projectToDelete)?.proyecto}</strong> tiene asociados <strong>{reportCount}</strong> reportes. Toda la información será borrada y no habrá forma de restaurarla. ¿Desea borrar?</p>
            {isDeleting ? ( // Mostrar mensaje y spinner si está eliminando
              <div className="flex items-center justify-center mt-4">
                <span className="loading loading-spinner loading-xs"></span>
                <span className="ml-2">Borrando...</span>
              </div>
            ) : (
              <div className="modal-action flex justify-between">
                <button className="btn" onClick={() => handleDeleteProject(projectToDelete)}>Sí, borrar</button>
                <button className="btn" onClick={() => setIsDialogOpen(false)}>Cancelar</button>
              </div>
            )}
          </div>
        </dialog>
      )}
    </div>
  );
};

export default Proyectos;
