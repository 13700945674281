import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useUsuario } from '../../context/usuario';

// Configura el worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const Reporte = () => {
  const { proyectoKey, reporteKey } = useParams();
  const { user, loading: usuarioLoading } = useUsuario(); // Cambiado de usuario a user

  const [reporte, setReporte] = useState(null);
  const [links, setLinks] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchReporteAndDetails = useCallback(async () => {
    if (!reporteKey) {
      setError('reporteKey no están definidos');
      setLoading(false);
      return;
    }

    try {
      const { data: reporteData, error: reporteError } = await supabase
        .from('reportes')
        .select('*, proyectos(proyecto)')
        .eq('key', reporteKey)
        .single();

      if (reporteError) {
        setError(reporteError.message);
        setLoading(false);
        return;
      }

      setReporte(reporteData);

      const { data: linksData, error: linksError } = await supabase
        .from('links')
        .select('*')
        .eq('reporte_key', reporteKey);

      if (linksError) {
        setError(linksError.message);
        setLoading(false);
        return;
      }

      setLinks(linksData);

      const { data: archivosData, error: archivosError } = await supabase
        .from('archivos')
        .select('*')
        .eq('reporte_key', reporteKey);

      if (archivosError) {
        setError(archivosError.message);
        setLoading(false);
        return;
      }

      setArchivos(archivosData);
    } catch (error) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  }, [reporteKey]);

  const checkAdminPermissions = useCallback(async () => {
    if (!user?.id) { // Cambiado de usuario a user
      setError('user.id no están definidos'); // Cambiado de usuario a user
      return;
    }

    try {
      const { data, error } = await supabase
        .from('colaboradores')
        .select('perfil')
        .eq('proyecto_key', proyectoKey)
        .eq('usuario_id', user.id) // Cambiado de usuario a user
        .single();

      if (error) {
        console.error("Error en la consulta a la base de datos:", error.message || error);
        setError(error.message || 'Error en la consulta a la base de datos');
        return;
      }

      if (data?.perfil === 'admin') {
        setIsAdmin(true);
      } else {
        console.log("El usuario no es administrador");
      }
    } catch (error) {
      console.error("Error al verificar permisos de administrador:", error.message || error);
      setError(error.message || 'Error checking admin permissions.');
    }
  }, [user?.id, proyectoKey]); // Cambiado de usuario a user

  useEffect(() => {
    if (user) { // Cambiado de usuario a user
      fetchReporteAndDetails();
      checkAdminPermissions();
    }
  }, [proyectoKey, reporteKey, user, fetchReporteAndDetails, checkAdminPermissions]); // Cambiado de usuario a user

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error);
    setError('Failed to load PDF file.');
  };

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('reportes')
        .delete()
        .eq('key', reporteKey);

      if (error) {
        setError(error.message);
        return;
      }

      // Redirigir o actualizar la UI después de la eliminación
      // Por ejemplo, redirigir a la página principal
      window.location.href = '/';
    } catch (error) {
      setError('Error deleting report.');
    }
  };

  if (loading || usuarioLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <button
        onClick={() => window.location.href = `/proyecto/${proyectoKey}` }
        className="bg-gray-500 text-white px-4 py-2 mb-4"
      >
        {reporte?.proyectos?.proyecto || 'Proyecto'}
      </button>
      {reporte && (
        <div className="mb-6">
          <h1 className="text-3xl font-bold">{reporte.reporte}</h1>
          {reporte.descripcion && (
            <p><strong>Descripción:</strong> {reporte.descripcion}</p>
          )}
          <p><strong>Fecha de Creación:</strong> {new Date(reporte.fecha_creacion).toLocaleString()}</p>
        </div>
      )}
      <div className="relative mb-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">Links</h1>
          {isAdmin && (
            <button className="bg-blue-500 text-white px-4 py-2 ml-auto">
              Agregar Link
            </button>
          )}
        </div>
      </div>
      <ul className="list-disc pl-6">
        {links.map((link) => (
          <li key={link.id}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.descripcion}</a>
          </li>
        ))}
      </ul>

      <div className="relative mb-4 mt-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">Archivos</h1>
          {isAdmin && (
            <button
              className="bg-blue-500 text-white px-4 py-2 ml-auto"
              onClick={() => window.location.href = `/proyecto/${proyectoKey}/reporte/${reporteKey}/subir-archivo`}
            >
              Agregar Archivo
            </button>
          )}
        </div>
      </div>
      <ul className="list-disc pl-6">
        {archivos.map((archivo) => (
          <li key={archivo.id}>
            {archivo.extension === 'pdf' && (
              <div className="flex justify-center mt-6">
                <div style={{ width: '100%', maxWidth: '800px' }}>
                  <Document
                    file={archivo.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                  >
                    <Page pageNumber={pageNumber} width={800} />
                  </Document>
                  {numPages > 1 && (
                    <div className="flex justify-between mt-2">
                      <button
                        disabled={pageNumber <= 1}
                        onClick={() => setPageNumber(pageNumber - 1)}
                        className="bg-gray-300 px-4 py-2"
                      >
                        Previous
                      </button>
                      <button
                        disabled={pageNumber >= numPages}
                        onClick={() => setPageNumber(pageNumber + 1)}
                        className="bg-gray-300 px-4 py-2"
                      >
                        Next
                      </button>
                    </div>
                  )}
                  <div className="flex justify-center mt-4">
                    <a
                      href={archivo.url}
                      download
                      className="bg-green-500 text-white px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver PDF
                    </a>
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>

      <button onClick={handleDelete} className="bg-red-500 text-white px-4 py-2 mt-4">
        Eliminar Reporte
      </button>
    </div>
  );
};

export default Reporte;
