import React, { useEffect, useState } from 'react';
import { supabase } from '../../lib/supabaseClient';

const ChatProyecto = ({ proyectoKey, usuario, urlImagen, nombre, apellido }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    console.log(proyectoKey);
    console.log(usuario);
    console.log(urlImagen);
    console.log(nombre);
    console.log(apellido);
    
    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from('chat')
        .select(`
          *,
          usuarios (
            nombre,
            apellido,
            url_imagen
          )
        `)
        .eq('proyecto', proyectoKey)
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching messages:', error);
      } else {
        setMessages(data);
      }
    };

    fetchMessages();

    const subscription = supabase
      .channel('public:chat')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'chat', filter: `proyecto=eq.${proyectoKey}` }, async payload => {
        const newMessage = payload.new;
        const { data: userData, error: userError } = await supabase
          .from('usuarios')
          .select('nombre, apellido, url_imagen')
          .eq('user_id', newMessage.usuario)
          .single();

        if (userError) {
          console.error('Error fetching user data:', userError);
        } else if (userData) {
          newMessage.usuarios = userData;
          setMessages(prevMessages => [...prevMessages, newMessage]);
        }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [proyectoKey, usuario, urlImagen, nombre, apellido]); // Asegúrate de incluir todas las dependencias

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const { error } = await supabase
      .from('chat')
      .insert([{ proyecto: proyectoKey, usuario, mensaje: newMessage }]);

    if (error) {
      console.error('Error sending message:', error);
    } else {
      setNewMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div>
      <div className="chat-input mt-20">
        <h2 className="text-xl font-bold mb-4 text-[var(--color-atention)] text-center">CHAT DEL PROYECTO</h2>
        <div className="flex items-center">

        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Escribe un mensaje..."
          className="border p-3 rounded w-full text-[var(--background)]"
          />
        <button className="bg-[var(--color-atention)] p-2 rounded-md ml-2 text-white" onClick={handleSendMessage}>Enviar</button>
          </div>
      </div>
      <div className="chat-messages mt-10">
        {messages.slice().reverse().map((msg, index) => (
          <div key={index} className={`chat ${msg.usuario === usuario ? 'chat-end' : 'chat-start'}`}>
            <div className="chat-image avatar">
              <div className="w-10 rounded-full">
                <img
                  alt="User Avatar"
                  src={msg.usuario === usuario ? urlImagen : msg.usuarios?.url_imagen || "https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"}
                />
              </div>
            </div>
            <div className="chat-header">
              {msg.usuario === usuario ? `${nombre} ${apellido}` : `${msg.usuarios?.nombre} ${msg.usuarios?.apellido}`}
            </div>
            <div className="chat-bubble">{msg.mensaje}</div>
            <div className="chat-footer opacity-50">
              {new Date(msg.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} 
              {/* Mostrar el día si no es hoy */}
              {new Date(msg.created_at).toDateString() !== new Date().toDateString() && (
                <span> - {new Date(msg.created_at).toLocaleDateString('es-ES', { weekday: 'long' })}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatProyecto;
