import React from 'react';
import './MyAccountSkeleton.css'; 

const MyAccountSkeleton = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-avatar"></div>
      <div className="skeleton-fields">
        <div className="skeleton-field">
          <div className="skeleton-label"></div>
          <div className="skeleton-input"></div>
        </div>
        <div className="skeleton-field">
          <div className="skeleton-label"></div>
          <div className="skeleton-input"></div>
        </div>
      </div>
      <div className="skeleton-fields">
        <div className="skeleton-field">
          <div className="skeleton-label"></div>
          <div className="skeleton-input"></div>
        </div>
        <div className="skeleton-field">
          <div className="skeleton-label"></div>
          <div className="skeleton-input"></div>
        </div>
      </div>
      <div className="skeleton-fields">
        <div className="skeleton-field">
          <div className="skeleton-label"></div>
          <div className="skeleton-input"></div>
        </div>
        <div className="skeleton-field">
          <div className="skeleton-label"></div>
          <div className="skeleton-input"></div>
        </div>
      </div>
      <div className="skeleton-divider"></div>
      <div className="skeleton-empresa"></div>
      <div className="skeleton-field-empresa">
          <div className="skeleton-label-empresa"></div>
          <div className="skeleton-input-empresa"></div>
      </div>
      <div className="skeleton-button"></div>
    </div>
  );
};

export default MyAccountSkeleton;
