import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { motion } from 'framer-motion';
import * as tf from '@tensorflow/tfjs';

// Configura TensorFlow.js para usar el backend de CPU
tf.setBackend('cpu').then(() => {
  console.log('Usando el backend de CPU');
});

const CrearReporte = () => {
  const { proyectoKey } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    reporte: '',
    fecha: new Date().toISOString().split('T')[0],
    encargado: '',
    cargo: '',
    url_qr: '',
    url_archivo1: '',
    url_archivo2: '',
    url_archivo3: '',
    url_recorrido1: '',
    url_recorrido2: '',
    url_recorrido3: '',
    url_puntos1: '',
    nota_puntos1: 0,
  });
  const [files, setFiles] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [proyecto, setProyecto] = useState(null);
  const [modelo3D, setModelo3D] = useState(null);
  const [hoveredModelo3D, setHoveredModelo3D] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [heatmapImage, setHeatmapImage] = useState(null);

  useEffect(() => {
    const fetchProyecto = async () => {
      const { data, error } = await supabase
        .from('proyectos')
        .select('proyecto')
        .eq('key', proyectoKey)
        .single();

      if (error) {
        setError(error.message);
      } else {
        setProyecto(data.proyecto);
      }
    };

    fetchProyecto();
  }, [proyectoKey]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setError(null);
    const maxSize = 3 * 1024 * 1024; // 3 MB en bytes
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];

    selectedFiles.forEach((file) => {
      if (file.size <= maxSize) {
        validFiles.push(file);
      } else {
        setError(`El archivo ${file.name} excede el tamaño máximo de 3 MB.`);
      }
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles].slice(0, 3));
  };

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleModelo3DChange = (e) => {
    setError(null);
    const maxSize = 45 * 1024 * 1024; // 45 MB en bytes
    const file = e.target.files[0];
    if (file && file.size <= maxSize && ['fbx', 'obj', '3ds'].includes(file.name.split('.').pop().toLowerCase())) {
      setModelo3D(file);
    } else if (file) {
      setError('Formato de archivo no soportado o el archivo excede el tamaño máximo de 45 MB.');
    }
  };

  const handleModelo3DRemove = () => {
    setModelo3D(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setError(null);
    setIsCreating(true);

    try {
      const reporteKey = Date.now().toString();
      let uploadedFileUrls = [];
      let modelo3DUrl = '';
      let heatmapScore = 0;
      let heatmapUrl = '';

      // Subir archivos
      if (files.length > 0) {
        uploadedFileUrls = await Promise.all(
          files.map(async (file, index) => {
            const fileExt = file.name.split('.').pop();
            const sanitizedFileName = file.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '_');
            const fileName = `${sanitizedFileName}-${reporteKey}.${fileExt}`;
            const filePath = `${proyectoKey}/${fileName}`;

            const { error: uploadError } = await supabase
              .storage
              .from('proyectos')
              .upload(filePath, file);

            if (uploadError) {
              throw uploadError;
            }

            const { data, error: urlError } = supabase
              .storage
              .from('proyectos')
              .getPublicUrl(filePath);

            if (urlError) {
              throw urlError;
            }

            return data.publicUrl;
          })
        );
      }

      // Subir modelo 3D
      if (modelo3D) {
        const fileExt = modelo3D.name.split('.').pop();
        const sanitizedFileName = modelo3D.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '_');
        const fileName = `${sanitizedFileName}-${reporteKey}.${fileExt}`;
        const filePath = `${proyectoKey}/${fileName}`;

        const { error: uploadError } = await supabase
          .storage
          .from('proyectos')
          .upload(filePath, modelo3D);

        if (uploadError) {
          throw uploadError;
        }

        const { data, error: urlError } = supabase
          .storage
          .from('proyectos')
          .getPublicUrl(filePath);

        if (urlError) {
          throw urlError;
        }

        modelo3DUrl = data.publicUrl;
      }

      // Subir imagen del mapa de calor
      if (heatmapImage) {
        const fileExt = heatmapImage.name.split('.').pop();
        const sanitizedFileName = heatmapImage.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '_');
        const fileName = `${sanitizedFileName}-${reporteKey}.${fileExt}`;
        const filePath = `${proyectoKey}/${fileName}`;

        const { error: uploadError } = await supabase
          .storage
          .from('proyectos')
          .upload(filePath, heatmapImage);

        if (uploadError) {
          throw uploadError;
        }

        const { data, error: urlError } = supabase
          .storage
          .from('proyectos')
          .getPublicUrl(filePath);

        if (urlError) {
          throw urlError;
        }

        heatmapUrl = data.publicUrl;
        heatmapScore = await analyzeHeatmap(heatmapImage);
      }

      const updatedFormData = {
        ...formData,
        url_archivo1: uploadedFileUrls[0] || '',
        url_archivo2: uploadedFileUrls[1] || '',
        url_archivo3: uploadedFileUrls[2] || '',
        url_modelo1: modelo3DUrl,
        url_puntos1: heatmapUrl,
        nota_puntos1: heatmapScore,
      };

      const { error } = await supabase
        .from('reportes')
        .insert([{ ...updatedFormData, proyecto_key: proyectoKey, key: reporteKey }]);

      if (error) {
        setError(error.message);
      } else {
        setSuccess(true);
        setFormData({
          reporte: '',
          fecha: new Date().toISOString().split('T')[0],
          encargado: '',
          cargo: '',
          url_qr: '',
          url_archivo1: '',
          url_archivo2: '',
          url_archivo3: '',
          url_recorrido1: '',
          url_recorrido2: '',
          url_recorrido3: '',
          url_puntos1: '',
          nota_puntos1: 0,
        });
        setFiles([]);
        navigate(`/proyecto/${proyectoKey}`);
      }
    } catch (error) {
      setError('Error al procesar la imagen.');
    }

    setIsCreating(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleQrClick = () => {
    document.getElementById('qrInput').click();
  };

  const handleQrChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedQr = e.target.files[0];
      const qrUrl = URL.createObjectURL(selectedQr);
      setFormData({ ...formData, url_qr: qrUrl });

      // Subir la imagen a Supabase
      const fileExt = selectedQr.name.split('.').pop();
      const fileName = `${proyectoKey}/qr-${Date.now()}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error: uploadError } = await supabase
        .storage
        .from('proyectos')
        .upload(filePath, selectedQr);

      if (uploadError) {
        console.error('Error al subir el QR:', uploadError.message);
        return;
      }

      const publicUrl = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/proyectos/${filePath}`;
      setFormData({ ...formData, url_qr: publicUrl });
    }
  };

  const handleHeatmapChange = (e) => {
    setError(null);
    const maxSize = 3 * 1024 * 1024; // 3 MB en bytes
    const file = e.target.files[0];
    if (file && file.size <= maxSize) {
      setHeatmapImage(file);
    } else if (file) {
      setError(`El archivo ${file.name} excede el tamaño máximo de 3 MB.`);
    }
  };

  const analyzeHeatmap = async (imageFile) => {
    try {
      console.log('Iniciando análisis de la imagen...');
      const image = await loadImage(imageFile);
      console.log('Imagen cargada:', image);

      const greenScore = calculateGreenScore(image);
      console.log('Proporción de verde calculada:', greenScore);

      const score = parseFloat((greenScore * 10).toFixed(1));
      console.log('Nota calculada:', score);

      return isNaN(score) ? 0 : score;
    } catch (error) {
      console.error('Error durante el análisis de la imagen:', error);
      throw new Error('Error al procesar la imagen');
    }
  };

  const loadImage = async (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        console.log('Imagen cargada en el canvas');
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        resolve(tf.browser.fromPixels(canvas));
      };
      img.onerror = (err) => {
        console.error('Error al cargar la imagen:', err);
        reject(err);
      };
    });
  };

  const calculateGreenScore = (imageTensor) => {
    const [red, green, blue] = tf.split(imageTensor, 3, 2);

    // Crear un tensor booleano que indica si un píxel es negro (R == G == B == 0)
    const isBlack = tf.logicalAnd(
      tf.equal(red, 0),
      tf.equal(green, 0),
      tf.equal(blue, 0)
    );

    // Crear un tensor booleano que indica si un píxel es blanco (R == G == B == 255)
    const isWhite = tf.logicalAnd(
      tf.equal(red, 255),
      tf.equal(green, 255),
      tf.equal(blue, 255)
    );

    // Crear un tensor booleano que indica si un píxel es gris (R == G == B)
    const isGray = tf.logicalAnd(
      tf.equal(red, green),
      tf.equal(green, blue)
    );

    // Excluir los píxeles negros, blancos y grises
    const isColorful = tf.logicalNot(tf.logicalOr(isBlack, tf.logicalOr(isWhite, isGray)));

    // Crear un tensor booleano que indica si un píxel es verde (G > R y G > B)
    const isGreen = tf.logicalAnd(
      tf.greater(green, red),
      tf.greater(green, blue)
    );

    // Calcular el número de píxeles verdes dentro del área del mapa de calor
    const greenPixels = tf.sum(tf.logicalAnd(isGreen, isColorful));
    const totalPixels = tf.sum(isColorful);

    // Agregar logs para depuración
    console.log('Total de píxeles coloridos:', totalPixels.arraySync());
    console.log('Total de píxeles verdes:', greenPixels.arraySync());

    // Calcular la proporción de píxeles verdes
    const greenScore = greenPixels.arraySync() / totalPixels.arraySync();
    console.log('Proporción de píxeles verdes:', greenScore);

    return greenScore;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <h2 className='text-2xl font-bold mt-10 text-center'>Nuevo Reporte <br /> {proyecto ? proyecto : ''} </h2>
      <motion.div
        className='container-mayor px-4 sm:px-6 lg:px-8'
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <span className="material-symbols-outlined ico-cerrar-form" onClick={handleCancel}>
          cancel
        </span>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {success && <div className="text-green-500 mb-4">Reporte creado exitosamente!</div>}
        <form onSubmit={handleSubmit} className="form-container space-y-6">
          <div className="form-row grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="text-left">Reporte:</label>
              <input type="text" name="reporte" value={formData.reporte} onChange={handleChange} required className="w-full" />
            </div>
            <div>
              <label className="text-left">Fecha:</label>
              <input type="text" name="fecha" value={formatDate(formData.fecha)} readOnly className="w-full h-10 rounded p-2" />
            </div>
          </div>

          <div className="form-row grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="text-left">Encargado:</label>
              <input type="text" name="encargado" value={formData.encargado} onChange={handleChange} className="w-full" />
            </div>
            <div>
              <label className="text-left">Cargo:</label>
              <input type="text" name="cargo" value={formData.cargo} onChange={handleChange} className="w-full" />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="relative file-upload-container flex items-center justify-center border rounded-lg px-2 py-6" style={{ borderColor: 'var(--secondary-color)' }}>
              <label htmlFor="file-upload" className={`cursor-pointer ${files.length === 3 ? 'pointer-events-none opacity-50' : ''}`}>
                <img src="https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-documento.png" alt="Subir Documento" className="w-16 h-16" />
              </label>
              <div className="flex flex-col items-start ml-4">
                <p className="font-bold text-sm">AGREGAR ARCHIVO</p>
                <p className="text-xs">Word / Pdf / Jpg</p>
              </div>
              <p className="text-xs absolute bottom-2 right-2">máximo 3 por reporte</p>
              <input id="file-upload" type="file" multiple onChange={handleFileChange} className="hidden" disabled={files.length === 3} />
            </div>
            <div className="uploaded-files flex flex-col justify-center h-full">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center pb-1"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <span
                    className="material-symbols-outlined cursor-pointer"
                    onClick={() => handleFileRemove(index)}
                  >
                    {hoveredIndex === index ? 'delete' : 'draft'}
                  </span>
                  <p className="ml-2 cursor-pointer text-sm">{file.name}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4" style={{ minWidth: '100%' }}>
            <div className="relative file-upload-container flex items-center justify-center border rounded-lg px-2 py-6 w-3/5" style={{ borderColor: 'var(--secondary-color)', width: '100%' }}>
              <label htmlFor="modelo3d-upload" className={`cursor-pointer ${modelo3D ? 'pointer-events-none opacity-50' : ''}`}>
                <img src="https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-modelo.png" alt="Subir Modelo 3D" className="w-16 h-16" />
              </label>
              <div className="flex flex-col items-start ml-4">
                <p className="font-bold text-sm">AGREGAR MODELO</p>
                <p className="text-xs">FBX / OBJ / 3DS</p>
              </div>
              <input
                id="modelo3d-upload"
                type="file"
                accept=".fbx, .obj, .3ds"
                onChange={handleModelo3DChange}
                className="hidden"
                disabled={!!modelo3D}
              />
              <p className="text-xs absolute bottom-2 right-2">1 por reporte</p>
            </div>
            <div className="uploaded-files flex flex-col justify-center h-full">
              {modelo3D && (
                <div
                  className="flex items-center pb-1"
                  onMouseEnter={() => setHoveredModelo3D(true)}
                  onMouseLeave={() => setHoveredModelo3D(false)}
                >
                  <span
                    className="material-symbols-outlined cursor-pointer"
                    onClick={handleModelo3DRemove}
                  >
                    {hoveredModelo3D ? 'delete' : 'draft'}
                  </span>
                  <p className="ml-2 cursor-pointer text-sm">{modelo3D.name}</p>
                </div>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <div className="flex items-center">
                <img src="https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-recorridos.png" alt="Agregar Recorrido 360°" className="w-10 h-10" />
                <div className="m-4">
                  <p className="font-bold text-sm">AGREGAR RECORRIDO 360°</p>
                  <p className="text-xs">Incrusta tu recorrido acá</p>
                </div>
              </div>
              <input type="url" name="url_recorrido1" placeholder="https://drive.google.com/drive/fol1" className="mb-2 p-2 border rounded text-sm" onChange={handleChange} />
              <input type="url" name="url_recorrido2" placeholder="https://drive.google.com/drive/fol2" className="mb-2 p-2 border rounded text-sm" onChange={handleChange} />
              <input type="url" name="url_recorrido3" placeholder="https://drive.google.com/drive/fol3" className="mb-2 p-2 border rounded text-sm" onChange={handleChange} />
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="qr-image-container flex justify-center" onClick={handleQrClick}>
                <img src={formData.url_qr || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-qr.png'} alt="QR" className="qr-image" />
              </div>
              <input
                type="file"
                id="qrInput"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleQrChange}
              />
              <p className="text-center mt-2">Carga el QR de tu reporte</p>
            </div>
          </div>

          <div className="form-group mt-4">
            <label htmlFor="heatmap-upload">Subir Mapa de Calor</label>
            <input
              type="file"
              id="heatmap-upload"
              accept="image/*"
              onChange={handleHeatmapChange}
              className="form-control"
            />
          </div>
          
          <br />
          <button className="btn-azul w-full sm:w-auto" type="submit" disabled={isCreating}>
            {isCreating ? 'Creando Reporte' : 'Crear Reporte'}
            {isCreating && <span className="loading loading-dots loading-xs pt-6 ml-2"></span>}
          </button>
        </form>
      </motion.div>
    </>
  );
};

export default CrearReporte;

