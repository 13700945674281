import React from 'react';
import PublicHeader from '../components/PublicHeader';
import Footer from '../components/Footer';

const PublicLayout = ({ children }) => {
  return (
    <div>
      <PublicHeader />
      {children}
      <Footer />
    </div>
  );
};

export default PublicLayout;