import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { UsuarioProvider } from './context/usuario';
import { createRoot } from 'react-dom/client';

// console.log('Index.js is running');

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <UsuarioProvider>
        <App />
      </UsuarioProvider>
    </Router>
  </React.StrictMode>
);