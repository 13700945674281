import React from 'react';
import './ProyectosSkeleton.css'; 

const ProyectosSkeleton = () => {
  return (
    <div className="skeleton-container-full">
      <div className="skeleton-titulo mt-20"></div>
      <div className="skeleton-button"></div>
      <br />
      <div className="skeleton-fields">
        <div className="skeleton-field">
          <div className="skeleton-input"></div>
        </div>
        <div className="skeleton-field">
          <div className="skeleton-input"></div>
        </div>
      </div>
      <div className="skeleton-proyectos-container">
        <div className="skeleton-proyecto">
          <div className="skeleton-header">
            <div className="skeleton-avatar"></div>
            <div className="skeleton-close"></div>
          </div>
          <div className="skeleton-body">
            <div className="skeleton-title"></div>
            <div className="skeleton-email"></div>
            <div className="skeleton-stats">
              <div className="skeleton-reports"></div>
              <div className="skeleton-rating"></div>
            </div>
          </div>
          <div className="skeleton-footer">
            <div className="skeleton-button"></div>
            <div className="skeleton-folder"></div>
          </div>
        </div>
        <div className="skeleton-proyecto">
          <div className="skeleton-header">
            <div className="skeleton-avatar"></div>
            <div className="skeleton-close"></div>
          </div>
          <div className="skeleton-body">
            <div className="skeleton-title"></div>
            <div className="skeleton-email"></div>
            <div className="skeleton-stats">
              <div className="skeleton-reports"></div>
              <div className="skeleton-rating"></div>
            </div>
          </div>
          <div className="skeleton-footer">
            <div className="skeleton-button"></div>
            <div className="skeleton-folder"></div>
          </div>
        </div>
        <div className="skeleton-proyecto">
          <div className="skeleton-header">
            <div className="skeleton-avatar"></div>
            <div className="skeleton-close"></div>
          </div>
          <div className="skeleton-body">
            <div className="skeleton-title"></div>
            <div className="skeleton-email"></div>
            <div className="skeleton-stats">
              <div className="skeleton-reports"></div>
              <div className="skeleton-rating"></div>
            </div>
          </div>
          <div className="skeleton-footer">
            <div className="skeleton-button"></div>
            <div className="skeleton-folder"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProyectosSkeleton;
