import React from 'react';

const Precios = () => {

  return (
    <div className="container mx-auto text-center">
      <h2 className="text-2xl font-bold mt-20">Planes de Precios</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
        <div className="border p-4 rounded shadow">
          <h3 className="text-xl font-semibold">Plan Básico</h3>
          <p className="mt-2">Hasta 10 proyectos</p>
          <p className="mt-2 font-bold mb-4">$10/mes</p>
          <a target="_blank" href="https://app.reveniu.com/checkout-custom-link/4gA8kwy3vCSLs3sX33ILOWCY7Ph5j3qY" rel="noreferrer">
            <button className="btn-azul mt-4">
              Suscribirse
            </button>
          </a>
        </div>
        <div className="border p-4 rounded shadow">
          <h3 className="text-xl font-semibold">Plan Pro</h3>
          <p className="mt-2">Hasta 50 proyectos</p>
          <p className="mt-2 font-bold">$25/mes</p>
          <a target="_blank" href="https://app.reveniu.com/checkout-custom-link/HZfK3rJxoULbd2TrMZbRkkVvqcYNhAxZ" rel="noreferrer">
            <button className="btn-azul mt-4">Suscribirse</button>
          </a> 
        </div>
        <div className="border p-4 rounded shadow">
          <h3 className="text-xl font-semibold">Plan Empresarial</h3>
          <p className="mt-2">Proyectos ilimitados</p>
          <p className="mt-2 font-bold">$50/mes</p>
          <a target="_blank" href="https://app.reveniu.com/checkout-custom-link/LQdhOghQMbIOtfxwODQFznowLIByk3E9" rel="noreferrer">
            <button className="btn-azul mt-4">Suscribirse</button>
          </a> 
        </div>
      </div>
      <div className="mt-20">
        <a href="/proyectos">
          <button className="btn-azul mt-4">
          Volver a Proyectos
          </button>
        </a>
      </div>
    </div>
  );
};

export default Precios;
