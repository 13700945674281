import React, { useState } from 'react';
import WebTorrent from 'webtorrent';

// Define 'global' de manera segura para el navegador
if (typeof global === 'undefined') {
  window.global = window;
}

function FileShare() {
  const [magnetURI, setMagnetURI] = useState(null);
  const client = new WebTorrent();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      client.seed(file, (torrent) => {
        setMagnetURI(torrent.magnetURI);
      });
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      {magnetURI && (
        <p>
          <a href={magnetURI} target="_blank" rel="noopener noreferrer">
            Enlace de descarga del archivo
          </a>
        </p>
      )}
    </div>
  );
}

export default FileShare;
