import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { supabase } from '../lib/supabaseClient';

const PrivateLayout = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Estado para manejar la carga

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();

      if (error || !user) {
        navigate('/'); // Redirigir a la raíz si no hay usuario
      } else {
        setLoading(false); // Si hay usuario, cambiar el estado de carga
      }
    };

    checkUser();
  }, [navigate]);

  // Mostrar un loader mientras se verifica el usuario
  if (loading) {
    return <div>Cargando...</div>; // O puedes mostrar un spinner
  }

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default PrivateLayout;
