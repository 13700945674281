import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient'; // Asegúrate de que esto esté aquí

const UsuarioContext = createContext();

export const UsuarioProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error);
      } else {
        setUser(user);
        if (user) {
          const { data: usuarioData, error: usuarioError } = await supabase
            .from('usuarios')
            .select('*')
            .eq('user_id', user.id)
            .single();
          if (usuarioError) {
            console.error('Error fetching usuario:', usuarioError);
          } else {
            setUsuario(usuarioData);
          }
        }
      }
      setLoading(false);
    };

    fetchUser();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
      if (session?.user) {
        const fetchUsuario = async () => {
          const { data: usuarioData, error: usuarioError } = await supabase
            .from('usuarios')
            .select('*')
            .eq('user_id', session.user.id)
            .single();
          if (usuarioError) {
            console.error('Error fetching usuario:', usuarioError);
          } else {
            setUsuario(usuarioData);
          }
        };
        fetchUsuario();
      } else {
        setUsuario(null);
      }
    });

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  return (
    <UsuarioContext.Provider value={{ user, usuario, loading, setUsuario }}>
      {children}
    </UsuarioContext.Provider>
  );
};

export const useUsuario = () => {
  return useContext(UsuarioContext);
};
