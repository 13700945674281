import React, { useState } from 'react';
import WebTorrent from 'webtorrent';

function FileDownload() {
  const [magnetURI, setMagnetURI] = useState('');
  const [fileBlobUrl, setFileBlobUrl] = useState(null);
  const client = new WebTorrent();

  const handleDownload = () => {
    client.add(magnetURI, (torrent) => {
      torrent.files[0].getBlobURL((err, url) => {
        if (err) return console.error(err);
        setFileBlobUrl(url);
      });
    });
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Pega el enlace Magnet aquí"
        value={magnetURI}
        onChange={(e) => setMagnetURI(e.target.value)}
      />
      <button onClick={handleDownload}>Descargar archivo</button>
      {fileBlobUrl && (
        <p>
          <a href={fileBlobUrl} download>
            Haz clic aquí para descargar el archivo
          </a>
        </p>
      )}
    </div>
  );
}

export default FileDownload;
