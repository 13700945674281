import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import ChatProyecto from './ChatProyecto';
import { useUsuario } from '../../context/usuario';

const Proyecto = () => {
  const { clienteRut, proyectoKey } = useParams();
  const { usuario, loading: usuarioLoading } = useUsuario();
  const [proyecto, setProyecto] = useState(null);
  const [reportes, setReportes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [usuarioProyecto, setUsuarioProyecto] = useState(null);
  const [activeTab, setActiveTab] = useState('reportes'); // Estado para controlar la pestaña activa

  useEffect(() => {
    const fetchProyectoAndReportes = async () => {
      try {
       
        const { data: proyectoData, error: proyectoError } = await supabase
          .from('proyectos')
          .select('*')
          .eq('key', proyectoKey)
          .single();

        if (proyectoError) {
          setError(proyectoError.message);
          setLoading(false);
          return;
        }

        setProyecto(proyectoData);

       
        const { data: reportesData, error: reportesError } = await supabase
          .from('reportes')
          .select('*')
          .eq('proyecto_key', proyectoKey);

        if (reportesError) {
          setError(reportesError.message);
          setLoading(false);
          return;
        }

       
        for (let i = 0; i < reportesData.length; i++) {
          const reporte = reportesData[i];
          const { data: linksData, error: linksError } = await supabase
            .from('links')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (linksError) {
            setError(linksError.message);
            setLoading(false);
            return;
          }

          reporte.links_count = linksData.length;
        }

       
        for (let i = 0; i < reportesData.length; i++) {
          const reporte = reportesData[i];
          const { data: archivosData, error: archivosError } = await supabase
            .from('archivos')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (archivosError) {
            setError(archivosError.message);
            setLoading(false);
            return;
          }

          reporte.archivos_count = archivosData.length;
        }

        setReportes(reportesData);
      } catch (error) {
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    if (proyectoKey) {
      fetchProyectoAndReportes();
    } else {
      setLoading(false);
      setError('proyectoKey is undefined');
    }
  }, [clienteRut, proyectoKey]);

  useEffect(() => {
    const fetchUsuarioProyecto = async () => {
      if (proyecto?.correo) {
        const { data: usuarioData, error: usuarioError } = await supabase
          .from('usuarios')
          .select('*')
          .eq('email', proyecto.correo)
          .single();

        // console.log(usuarioData);
        // console.log(proyecto.correo);

        if (!usuarioError) {
          setUsuarioProyecto(usuarioData);
        }
      }
    };

    fetchUsuarioProyecto();
  }, [proyecto]);

  if (loading || usuarioLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 mt-10 mb-20">
      <div className="relative w-full flex flex-col items-center">
        
        <h1 className="text-3xl font-bold text-center">Listado de Reportes <br /><br /> <span className="text-2xl font-bold">{proyecto.proyecto}</span></h1>
        <img src={proyecto.url_imagen} alt="Imagen del Proyecto" className="w-20 h-20 rounded mb-4 mt-4" />
        <div className="btn-volver">
          <div className="flex items-center" onClick={() => navigate('/proyectos')}>
            <div className="back circular border">
              <span className="material-symbols-outlined">arrow_back</span>
            </div>
            <span className="text-xs cursor-pointer">Volver</span>
          </div>
        </div>
      </div>
      
      <div className="flex justify-center items-center mt-20 mb-20">
        <div role="tablist" className="tabs tabs-lifted">
          <button
            role="tab"
            className={`tab ${activeTab === 'reportes' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('reportes')}
          >
            Reportes
          </button>
          <button
            role="tab"
            className={`tab ${activeTab === 'colaboradores' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('colaboradores')}
          >
            Colaboradores
          </button>
          <button
            role="tab"
            className={`tab ${activeTab === 'chat' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('chat')}
          >
            Chat
          </button>
        </div>
      </div>

      {activeTab === 'reportes' && (
        <>
          <div className="flex justify-center mb-4">
            <button
              className="bg-[#D9E0E5] text-[#3A3A3A] py-6 px-10 rounded-xl flex items-center"
              onClick={() => navigate(`/proyecto/${proyectoKey}/crear-reporte`)}
            >
              <span className="material-symbols-outlined mr-2">add_circle</span>
              REPORTE
            </button>
          </div>
          
          {reportes.length > 0 ? (
        <>
          <div className="mb-4 flex justify-center items-center mt-12">
            <input
              type="text"
              placeholder="Buscar proyecto"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border p-3 rounded w-full max-w-[400px] text-[#3A3A3A]"
            />
            <select
              onChange={(e) => setSortOrder(e.target.value)}
              className="text-[#3A3A3A] p-3 rounded ml-2"
            >
              <option value="reportCountAsc">Ordenar por cantidad de reportes (Ascendente)</option>
              <option value="reportCountDesc">Ordenar por cantidad de reportes (Descendente)</option>
              <option value="fechaCreacionAsc">Ordenar por fecha de creación (Ascendente)</option>
              <option value="fechaCreacionDesc">Ordenar por fecha de creación (Descendente)</option>
              <option value="calificacionAsc">Ordenar por calificación (Ascendente)</option>
              <option value="calificacionDesc">Ordenar por calificación (Descendente)</option>
            </select>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {reportes
              .sort((a, b) => {
               
                if (sortOrder === 'reportCountAsc') return a.links_count - b.links_count;
                if (sortOrder === 'reportCountDesc') return b.links_count - a.links_count;
                if (sortOrder === 'fechaCreacionAsc') return new Date(a.fecha_creacion) - new Date(b.fecha_creacion);
                if (sortOrder === 'fechaCreacionDesc') return new Date(b.fecha_creacion) - new Date(a.fecha_creacion);
                if (sortOrder === 'calificacionAsc') return a.calificacion - b.calificacion;
                if (sortOrder === 'calificacionDesc') return b.calificacion - a.calificacion;
                return 0;
              })
              .filter((reporte) => {
               
                return (
                  reporte.reporte.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  reporte.descripcion.toLowerCase().includes(searchTerm.toLowerCase())
                );
              })
              .map((reporte) => (
                <div key={reporte.id} className="bg-white p-4 border rounded shadow mt-10">
                  <h2 className="text-xl font-bold">
                    <Link to={`/proyecto/${proyectoKey}/reporte/${reporte.key}`}>{reporte.reporte}</Link>
                  </h2>
                  <p><strong>Fecha:</strong> {new Date(reporte.fecha).toLocaleString()}</p>
                  <p>
                    <span className="material-symbols-outlined text-yellow-500 mr-2">star</span>
                    <strong>Nota:</strong> {reporte.nota_puntos1}
                  </p>
                </div>
              ))}
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center mt-12 mb-4">
          <div className="bg-gray-200 p-4 md:p-10 text-center w-full md:w-4/5 rounded-2xl">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-[var(--color-atention)]">REPORTES</h2>
              <p className="text-sm md:text-xl text-[var(--secondary-background)] text-justify pb-10">
              A través de los reportes podrás centralizar y visualizar toda la información relevante de un proyecto, como avances, modificaciones, archivos, modelos 3d, etc..., en un formato digital integrado. Estos reportes permiten una mayor precisión y eficiencia, facilitando la toma de decisiones en tiempo real y mejorando la comunicación entre todos los involucrados. Con un sistema ágil y automatizado, los reportes BIM aseguran que cada aspecto del proyecto esté alineado con los más altos estándares del rubro.
              </p>
          </div>
        </div>
      )}

        </>
      )}

      {activeTab === 'colaboradores' && usuarioProyecto && (
        <div className="">
          <div className="logo-empresa-container">
            <img src={usuarioProyecto.logo || 'url_imagen_grande'} alt="Logo Grande" className="logo-empresa" />
            <img src={usuarioProyecto.url_imagen || 'url_imagen_pequeno'} alt="Logo Pequeño" className="logo-usuario" />
          </div>
          <div className="empresa-info text-left">
            <span className="nombre-empresa">{usuarioProyecto.empresa || 'Tu Empresa'}</span>
            <span className="email-empresa">{usuarioProyecto.email || 'cargando email'}</span>
          </div>
        </div>
      )}

      {activeTab === 'chat' && (
        <ChatProyecto 
          proyectoKey={proyectoKey} 
          usuario={usuario?.user_id} 
          urlImagen={usuario?.url_imagen}
          nombre={usuario?.nombre}
          apellido={usuario?.apellido}
        />
      )}
    </div>
  );
};

export default Proyecto;
