/* eslint-disable */

// Define 'global' de manera segura para el navegador
if (typeof global === 'undefined') {
	window.global = window;
}

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import Home from './components/Home';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import MyAccount from './components/MyAccount';
import Proyecto from './components/proyectos/Proyecto';
import Proyectos from './components/proyectos/Proyectos';
import Reportes from './components/reportes/Reportes';
import Reporte from './components/reportes/Reporte';
import CrearReporte from './components/reportes/CrearReporte';
import CrearColaborador from './components/reportes/CrearColaborador';
import AceptarInvitacion from './components/reportes/AceptarInvitacion';
import SubirArchivo from './components/reportes/SubirArchivo';
import NuevoProyecto from './components/proyectos/NuevoProyecto';
import EsperandoValidacion from './components/EsperandoValidacion';
import Dashboard from './components/Dashboard'; // Importa el nuevo componente Dashboard
import { UsuarioProvider } from './context/usuario'; // Importa el proveedor
import Logout from './components/Logout'; // Importa el componente Logout
import Precios from './components/Precios';
import EditarProyecto from './components/proyectos/editarProyecto';
import FileShare from './components/FileShare';
import FileDownload from './components/FileDownload';

function App() {

	const handleUploadSuccess = (data) => {
		console.log("Upload successful:", data);
		// Aquí puedes manejar lo que quieras hacer después de una carga exitosa
	};
	
	return (
		<UsuarioProvider> {/* Asegúrate de envolver toda la aplicación */}
			<Routes>
				{/* Rutas públicas */}
				<Route path="/" element={<PublicLayout><Home /></PublicLayout>} />
				<Route path="/ingresar" element={<PublicLayout><LoginForm /></PublicLayout>} />
				<Route path="/registro" element={<PublicLayout><RegisterForm /></PublicLayout>} />
				<Route path="/aceptar-invitacion" element={<PublicLayout><AceptarInvitacion /></PublicLayout>} />
				<Route path="/esperando-validacion" element={<PublicLayout><EsperandoValidacion /></PublicLayout>} />
				<Route path="/precios" element={<PublicLayout><Precios /></PublicLayout>} />
				<Route path="/salir" element={<PublicLayout><Logout /></PublicLayout>} /> {/* Nueva ruta para cerrar sesión */}
				<Route path="/file-share" element={<PublicLayout><FileShare /></PublicLayout>} /> {/* Nueva ruta para compartir archivos */}
				<Route path="/file-download" element={<PublicLayout><FileDownload /></PublicLayout>} /> {/* Nueva ruta para descargar archivos */}
				{/* Otras rutas públicas */}

				{/* Rutas privadas */}
				<Route path="/mis-datos" element={<PrivateLayout><MyAccount /></PrivateLayout>} />
				<Route path="/proyectos" element={<PrivateLayout><Proyectos /></PrivateLayout>} />
				<Route path="/proyectos/nuevo-proyecto" element={<PrivateLayout><NuevoProyecto /></PrivateLayout>} />
				<Route path="/proyectos/editar-proyecto/:proyectoKey" element={<PrivateLayout><EditarProyecto /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey" element={<PrivateLayout><Proyecto /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey/crear-reporte" element={<PrivateLayout><CrearReporte /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey/reporte/:reporteKey" element={<PrivateLayout><Reporte /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey/reporte/:reporteKey/subir-archivo" element={<PrivateLayout><SubirArchivo onUploadSuccess={handleUploadSuccess} /></PrivateLayout>} />
				<Route path="crear-colaborador" element={<PrivateLayout><CrearColaborador /></PrivateLayout>} />
				<Route path="/reportes" element={<PrivateLayout><Reportes /></PrivateLayout>} />
				<Route path="/dashboard" element={<PrivateLayout><Dashboard /></PrivateLayout>} />
			</Routes>
		</UsuarioProvider>
	);
}

export default App;
/* eslint-enable */
